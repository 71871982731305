import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MapWrapper from '../LocationMap';
import { getAllAssetsForFolder, getCustomerAssets } from '../../services/assetService';
import riskColor from '../../utils/riskColor';

import { useHierarchyContext } from '../../context/HierarchyContext';

export default function InvestmentAssetsLocationMap({
    entityType,
    entityId,
    riskDataFilters,
    investmentId,
    folderId,
    customerId,
    tableData,
}) {
    // TEMPORARY HACK TO EXCLUDE SPECIFIC CUSTOMER W/ LARGE DATA SET TO LOAD WITHOUT MAP
    const { currentPath } = useHierarchyContext();
    const customerIdFromPath = currentPath.substring(1).split('/')?.[1];
    const mapProhibited =
        customerIdFromPath === '2109' ||
        customerIdFromPath === '4' ||
        customerIdFromPath === '1122';

    if (mapProhibited) {
        return <></>;
    }

    const [locations, setLocations] = useState([]);
    const [showMap, setShowMap] = useState(false);

    const addRiskInfoToInvestmentLocations = (locationsArray, assetTableData) => {
        const locationsWithColor = locationsArray.map((location) => {
            const findTableData = assetTableData.find(
                (asset) => asset.entityId === location.asset_id
            );

            const assetObject = findTableData || {
                absoluteValue: '$0.00',
                relativeValue: '0.0 %',
            };

            return {
                ...location,
                ...assetObject,
                color: riskColor.determineColor(assetObject.relative_value, 'relative'),
            };
        });

        return locationsWithColor;
    };

    const updateLocationEntityNames = (locs, assets) => {
        locs.forEach((loc) => {
            const matchingAsset = assets.find((asset) => asset.asset_id === loc.asset_id);

            if (matchingAsset) {
                loc.entity_name = matchingAsset.asset_name;
            }
        });
        return locs;
    };

    useEffect(() => {
        async function fetchData() {
            if (entityId) {
                const assetCountData = await getCustomerAssets(customerId, { assetcount: true });
                if (assetCountData.active_asset_count < 10000) {
                    const investmentAssetLocationsResponse = await getAllAssetsForFolder({
                        customerId,
                        folderId,
                        getLocations: true,
                    });

                    // pull locations from our Assets response
                    const investmentAssetLocations = investmentAssetLocationsResponse.reduce(
                        (accumulator, currentAsset) =>
                            accumulator.concat(currentAsset.location_list),
                        [],
                    );

                    // add risk Data to locations from our Assets response
                    const investmentAssetLocationsWithRisks = addRiskInfoToInvestmentLocations(
                        investmentAssetLocations,
                        tableData,
                        riskDataFilters,
                    );

                    const investmentAssetLocationsWithRisksAndNames = updateLocationEntityNames(
                        investmentAssetLocationsWithRisks,
                        investmentAssetLocationsResponse,
                    );

                    setShowMap(true);
                    setLocations(investmentAssetLocationsWithRisksAndNames);
                }
            }
        }
        fetchData();
    }, [riskDataFilters, entityId, entityType, folderId, investmentId, showMap]);

    return <>{showMap && <MapWrapper locations={locations} displayLegend={true} />}</>;
}

InvestmentAssetsLocationMap.propTypes = {
    customerId: PropTypes.string || PropTypes.number,
    entityId: PropTypes.string || PropTypes.number,
    entityType: PropTypes.number,
    folderId: PropTypes.string || PropTypes.number,
    groupId: PropTypes.number || PropTypes.string,
    handleClick: PropTypes.func,
    investmentId: PropTypes.number || PropTypes.string,
    riskDataFilters: PropTypes.object,
};
